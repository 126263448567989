@import url(https://fonts.googleapis.com/css?family=Varela+Round&display=swap);
@import url(https://fonts.googleapis.com/css?family=Rock+Salt&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

html, body, #root, .App, main, .homepage {
  height: 100%;
}

h2 {
  font-weight: lighter;
}

.homePage {
  background: url('https://i.imgur.com/wwqi1I9.jpg') no-repeat center center fixed;
  background-size: cover;
  margin-top: -6em;
  height: 100%;
  text-align: left;
}

.homePage h1 {
  margin-left: 8%;
  padding-top: 5%;
}

.homePage p {
  margin-left: 10%;
  font-size: 1.3em;
}

.homePage ul {
  margin-left: 5%;
  padding-top: 3%;
  list-style: none;
  width: 6.5em;

}

.homePage ul li {
  padding: .5em;
  border: solid black 1px;
  border-radius: 5px;
  width: 5em;
  margin-top: 40%;
  text-align: center;
  -webkit-transition-duration: .5s;
          transition-duration: .5s;
}

.homePage ul li:hover {
  background-color: rgb(182, 141, 39);
}

h1 {
  font-family: 'Rock Salt', cursive;
}

.nav-ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 100%;
  font-family: 'Varela Round', sans-serif;
}

a {
  color: black;
  text-decoration: none;
}

.nav-ul li {
  padding-top: 1em;
  padding-bottom: 1em;
  margin-top: -1em;
  margin-bottom: -1em;
  padding-left: 1em;
  padding-right: 1em;
  -webkit-transition-duration: 1s;
          transition-duration: 1s;
  font-size: 1em;
}

.nav-ul li:hover {
  background-color: white;
  border-radius: 40%;
}

header {
  background-color: rgb(240, 224, 82);
  margin-top: -1em;
  padding-top: 1em;
  padding-bottom: 0em;
  position: fixed;
  width: 100%;
  top: 0;
  opacity: 1;
}

.portfolio-sidebar h1 {
  font-size: 2em;
  margin-right: .5em;
  margin-left: .5em;
}

.aboutMe-img {
  width: 30%;
  border-radius: 50%;
}

main {
  margin-top: 6em;
  display: block;
}

.collection-img, .piece-img {
  width: 70%;
  -webkit-transition-duration: .5s;
          transition-duration: .5s;
}

.collection-div, .piece-li {
  display: inline-block;
  width: 50%;
}

.piece-div, .collection-div {
  margin-right: -5%
}

.portfolio-ul, .collection-pieces-ul {
  display: inline-block;
}

.collections-pieces-ul li {
  margin-right: -5%;
  margin-top: 3em;
}

.portfolio-div {
  display: inline-flex;
  width: 100%;
}

.portfolio-sidebar, .collection-sidebar, .pieceView-sidebar {
  position: fixed;
  width: 30%;
  border-right: solid black 2px;
  height: 60%;
  margin-top: 2%;
}

.portfolio-collections-list, .collections-pieces-list {
  width: 65%;
  margin-left: 37%;
  margin-bottom: 5%;
}

.portfolio-collections-list {
  margin-top: -5%;
}

.portfolio-h1 {
  font-size: 3em;
}

h2 {
  font-size: 1.2em;
}

.icon {
  -webkit-transition-duration: .5s;
          transition-duration: .5s;
}

.icon:hover {
  cursor: pointer;
  -webkit-transform: translate(.3em, -.3em);
          transform: translate(.3em, -.3em);
}

.contact-ul {
  list-style: none;
  padding-top: 1em;
}

.contact-ul li {
  margin-top: 3em;
}

#email-li p {
  margin-top: -.5em;
}

img {
  border-radius: 15px;
  box-shadow: rgb(73, 73, 73) 5px 5px 5px;
}

.collection-img:hover, .piece-img:hover {
  width: 75%;
}

.aboutMe-img {
  display: inline-block;
  line-height: normal;
}

p {
  line-height: 1.5;
}

.image-p {
  display: inline-block;
  width: 30%;
  font-size: 2em;
  line-height: normal;
  padding-left: 1em;
  padding-right: 1em;
}

.body-p {
  margin-left: auto;
  margin-right: auto;
  width: 35%;
  text-align: left;
}
.about-me-inline-container > * {
  vertical-align: middle;
}

.collection-div {
  padding-top: 4em;
}

.collections-pieces-ul, .portfolio-ul {
  margin-left: -20%;
  margin-right: 3%;
}

.collection-description {
  text-align: left;
  margin-left: 5%;
  margin-right: 5%;
}

.back-button {
  cursor: pointer;
  padding: 1em;
  border-radius: 50%;
  font-size: 1em;
  -webkit-transition-duration: 1s;
          transition-duration: 1s;
  outline: 0;
  margin-top: 2%;
}

.back-button:hover {
  color: white;
  background-color: black;
}

.back-button:active {
  box-shadow: 0 5px #666;
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}

.piece-view-div {
  width: 100%;
  display: inline-block;
  margin-top: -2%;
}

.pieceview-img {
  width: 50%;
  box-shadow: none;
  margin-left: 30%;
  -webkit-transition-duration: .5s;
          transition-duration: .5s;
}

.pieceview-img:hover {
  width: 57%;
}

.pieceView-sidebar {
  padding-top: 5%;
  height: 50%;
}

#portfolio-h2 {
  font-size: 2em;
}
.collection-view-div ul {
  list-style: none;
}
.pieceView-sidebar p {
  text-align: left;
  margin-left: 25%;
  margin-right: 3%;
}
.portfolio-sidebar p {
  text-align: left;
  margin-left: 5%;
  margin-right: 5%;
}

.mobile-portfolio-display, .mobile-collection-display, .mobile-piece-view-display {
  display: none;
}
.mobile-back-button {
  display: none;
}
.last-aboutMe-p {
  margin-bottom: 5em;
}
.left {
  margin-left: 15%;
}
.right {
  margin-right: 15%;
}

@media screen and (max-width: 600px) {
  .homePage li {
    background: rgba(221, 219, 219, 0.726);
  }
  .nav-ul li {
    padding-top: 1em;
    padding-bottom: 1em;
    margin-top: -1em;
    margin-bottom: -1em;
    padding-left: 0;
    padding-right: 0;
  }
  header {
    padding-top: .5em;
    padding-bottom: 0em;
  }
  .nav-ul {
    margin-left: -2em;
  }
  .portfolio-sidebar, .collection-sidebar, .pieceView-sidebar {
    display: none;
  }
  .mobile-portfolio-display, .mobile-collection-display, .mobile-piece-view-display {
    display: block;
    margin-top: -2em;
  }
  .portfolio-ul, .collection-pieces-ul {
    display: block;
  }
  .mobile-portfolio-display h2 {
    margin-top: -.5em;
  }
  .mobile-portfolio-display p {
    margin-top: -.5em;
    text-align: left;
    margin-left: 3%;
    margin-right: 3%;
  }
  .portfolio-div, .collection-view-div {
    display: block;
    width: 100%;
  }
  .mobile-portfolio-display h1 {
    font-size: 2em;
  }
  .portfolio-collections-list, .collections-pieces-list {
    width: 100%;
    margin-left: 0;
  }
  .portfolio-ul {
    margin-top: -3em;
  }
  .portfolio-ul li, .collections-pieces-list li {
    display: block;
    width: 100%;
    margin-left: -7%;
  }
  .collection-img:hover, .piece-img:hover {
    width: 100%;
  }
  .mobile-back-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
  }
  .collections-pieces-ul, .portfolio-ul {
    margin-left: auto;
    margin-right: auto;
  }
  .portfolio-collections-list li {
    margin-top: -2em;
  }
  .pieceview-img {
    width: 90%;
    margin-left: 0%;
  }
  .pieceview-img:hover, .collection-img:hover, .piece-img:hover {
    width: 90%;
  }
  .contact-ul {
    margin-left: -10%;
  }
  .aboutMe-img {
    width: 90%;
  }
  .image-p {
    display: inline-block;
    width: 90%;
    font-size: 2em;
  }
  .body-p {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
  .collection-img, .piece-img {
    width: 90%;
  }
  .collections-pieces-ul li {
    margin-top: 0;
  }
  .aboutMe header {
    margin-left: -1em;
  }
}
